/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Vendored libraries
import './globals';
import "bootstrap";

// Make Rails standard helpers (ie data-confirm, data-remote etc) work as
// expected
import Rails from "@rails/ujs";
Rails.start();

// Hook up Stimulus
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
const _ = require('lodash');
application.load(definitionsFromContext(context));

// Initialize flatpickr
import flatpickr from "flatpickr";
import * as flatpickrMonthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import { Danish as flatpickrDanish } from "flatpickr/dist/l10n/da"
$(document).ready(() => {
  $("[data-flatpickr]").each((_, elm) => {
    var elmOpts = $(elm).data("flatpickr");
    if (elmOpts.monthSelect) {
      elmOpts['prevArrow'] = elmOpts['nextArrow'] = null;
      elmOpts['plugins'] = [
        flatpickrMonthSelectPlugin($.extend({
          altFormat: 'm.Y',
        }, elmOpts.monthSelect))
      ];
    }
    flatpickr(elm, $.extend({
      locale: flatpickrDanish,
      altInput: true,
      altFormat: "d.m.Y",
    }, elmOpts));
  });
});

// Initialize selectize
import "selectize";
$(document).ready(() => {
  $("select[data-selectize]").each((_, elm) => {
    const $elm = $(elm);
    const citySelector = $elm.data("selectize");
    $elm.selectize({
      openOnFocus: false,
      onChange: () => {
        const cityName = $elm.text().replace(/^\d+\s*/, "");
        $(citySelector).val(cityName);
      },
      render: {
        item: (data, escape) => {
          return `<div class="item">${escape(data.value)}</div>`;
        }
      }
    });
  });
});

// Initialize duplicate fields
$(document).ready(() => {
  $("[data-duplicate-fields='target']").each((_, elm) => {
    const source = $("[data-duplicate-fields='source']");
    const target = $(elm);
    const a = $(
      "<a href='' data-duplicate-fields='action'>brug samme adresse</a>"
    );
    a.click((e) => {
      e.preventDefault();
      $.each(['select', 'input'], function(_, fieldSelector) {
        target.find(`${fieldSelector}[data-duplicate-fields-id]:not(:disabled)`).each((_, elm) => {
          const targetElm = $(elm);
          const fieldName = targetElm.data("duplicate-fields-id");
          const sourceElm = source.find(
            `[data-duplicate-fields-id="${fieldName}"]:not(:disabled)`
          );

          if (targetElm.data("selectize")) {
            targetElm.data("selectize").setValue(sourceElm.val(), true);
          } else {
            targetElm.val(sourceElm.val()).trigger('change');
          }
        });
      });
    });
    $(elm).find("label:first").append(" (").append(a).append(")");
  });
});

// Initialize popover
$(document).ready(() => { $('[data-toggle="popover"').popover() });

// Initialize inputmask
import Inputmask from "inputmask/dist/inputmask/inputmask.numeric.extensions";
$(document).ready(() => {
  $('.currency, .percentage, .number').each(function() {
    var im = new Inputmask();
    im.mask(this);
  });
});

// Initialize dawa_autocomplete
import { dawaAutocomplete } from "dawa-autocomplete2/dist/js/dawa-autocomplete2.min"
$(document).ready(() => {
  $('[data-dawa-autocomplete]').each((_, elm) => {
    var opts = $(elm).data('dawa-autocomplete');
    function updateTargets(address) {
      if (address) {
        $.get( address.data.href, function( data ) {
          $('#cadastral-info').val(`${data.adgangsadresse.matrikelnr} - ${data.adgangsadresse.ejerlav.navn}`)
          $(opts.cadastral_number).filter(':not(:disabled)').val(data.adgangsadresse.matrikelnr);
          $(opts.cadastral_district_name).filter(':not(:disabled)').val(data.adgangsadresse.ejerlav.navn);
          $(opts.cadastral_district_identifier).filter(':not(:disabled)').val(data.adgangsadresse.ejerlav.kode);
        });
        $(opts.postal_code).filter(':not(:disabled)').val(address.data.postnr);
        $(opts.city).filter(':not(:disabled)').val(address.data.postnrnavn);
      } else {
        $(opts.postal_code).filter(':not(:disabled)').val('');
        $(opts.city).filter(':not(:disabled)').val('');
        $(opts.cadastral_number).filter(':not(:disabled)').val('');
      }
    }
    dawaAutocomplete(elm, {
      select(address) {
        let street = address.forslagstekst.split("\n").slice(0,-1).join(', ');
        let postal_code = address.data.postnr;
        let city = address.data.postnrnavn;
        setTimeout(() => { $(elm).val(street); }, 50);
        updateTargets(address);
        $(elm).trigger('select.dawaAutocomplete', { address: street, postal_code, city });
      }
    });
    $(elm).keyup(function(e) {
      if ($(this).val() === '') {
        updateTargets(null);
      }
    })
  });
});

$(document).on('change', 'select[id*="customer"][id$="_country"]', function() {
  let form = $(this).closest('.dawa-autocomplete');
  let countryNotSet = $(this).val() === '';
  let countryNotDK = $(this).val() !== 'DK';
  form.find('.country-dk').toggleClass('d-none', countryNotDK).find('input').attr('disabled', countryNotDK).val('');
  form.find('.country-not-dk').toggleClass('d-none', !countryNotDK).find('input').attr('disabled', countryNotSet || !countryNotDK).val('');
});

$(document).on('select.dawaAutocomplete', 'input[data-dawa-autocomplete]', function(_, address) {
  let existsUrl = $(this).data('exists-url');
  if (!existsUrl) {
    return;
  }

  Rails.ajax({
    type: 'GET',
    url: existsUrl,
    data: $.param(address),
    success: function() { $(this).removeClass('is-warning') }.bind(this),
    error: function() { $(this).addClass('is-warning') }.bind(this)
  });
});

// Initialize bootstrap-autocomplete
import "./autocomplete";
$(document).ready(() => {
  function toggleAjaxLoadingAnimation(loading) {
    $('.bootstrap-autocomplete + .input-icon-addon i.fe')
      .filter('.fe-search').toggleClass('d-none', loading).end()
      .filter('.fe-refresh-cw').toggleClass('d-none', !loading).end();
  }

  $('[data-autocomplete]').each(function() {
    var opts = $(this).data('autocomplete');
    $(this)
      .autoComplete({
        preventEnter: true,
        resolverSettings: {
          queryKey: 'query'
        },
        events: {
          searchPre: function(text) { toggleAjaxLoadingAnimation(true); return text; },
          searchPost: function(data) { toggleAjaxLoadingAnimation(false);  return data; }
        },
        formatResult(item) {
          if (!item._autoComplete && item.url) {
            item._autoComplete = this;
          }
          var highlight = function(text) {
            return item._autoComplete.showMatchedText(text || '', item._autoComplete.searchText);
          }
          return item.url ? {
            text: '',
            html: `
              <span class="d-block cursor-pointer">
                <span class="d-block text-truncate">${highlight(item.customer_names)}</span>
                <small class="d-block text-truncate text-sm text-muted">
                ${highlight(item.type === 'case' ? item.description : null)}
                </small>
                <small class="d-block text-muted">
                  <span class="badge badge-secondary">${item.state}</span>
                  <!--&bull;
                  ${item.date}-->
                </small>
              </span>
            `
          } : {
            text: item.label,
            disabled: true
          };
        }
      })
      .on('autocomplete.select', function(e, item) {
        window.location.href = item.url;
      })
      .on('autocomplete.freevalue', function() {
        $(this).closest('form').trigger('submit');
      })
      .on('focus', function() {
        $(this).autoComplete('show');
      });
  });
});

$(document).ready(() => {
  $('#scenarios-tab').on('click', function(event){
    var target_id = event.target.getAttribute('href')
    var target = $(`${target_id}-key-stats-pane`)

    target.siblings('.show').removeClass('show')
    target.siblings('.active').removeClass('active')

    target.addClass('show')
    target.addClass('active')
    $('#key-stats-title').text(event.target.text)
  })
});

$(document).ready(function() {
  var enableGasCheckbox = $('#enable-gas');
  var gasUsageInput = $('#gas-usage-input');
  
  // Hide gas usage input on page load
  gasUsageInput.hide();
  
  enableGasCheckbox.on('click', function() {
    if ($(this).prop('checked')) {
      gasUsageInput.show();
    } else {
      gasUsageInput.hide();
    }
  });
});

import noUiSlider from 'nouislider'
$(document).ready(function() {
  var currentYear = new Date().getFullYear();
  var sliderTypes = {
    price: { min: 0, max: 30000000, suffix: ' kr' },
    sqm: { min: 0, max: 300, suffix: ' m2' },
    plot_sqm: { min: 0, max: 10000, suffix: ' m2' },
    rooms: { min: 0, max: 20, suffix: '' },
    build_year: { min: 1900, max: currentYear, suffix: '' }
  };

  $.each(sliderTypes, function(type, range) {
    var slider = $(`#${type}-slider`);
    var isYearType = type === 'build_year';
    var suffix = range.suffix

    if (slider.length) {
      noUiSlider.create(slider.get(0), {
        start: [range.min, range.max],
        connect: true,
        step: 1,
        range: { min: range.min, max: range.max }
      });

      var formatValues = [
        $(`#min-${type}-field`),
        $(`#max-${type}-field`)
      ];

      var formatValuesInput = [
        $(`#min-${type}-input`),
        $(`#max-${type}-input`)
      ];

      slider.get(0).noUiSlider.on('update', function(values, handle) {
        var formattedValue = Math.trunc(values[handle]);

        if (isYearType) {
          formatValues[handle].text(formattedValue);
        } else {
          formatValues[handle].text(formattedValue.toLocaleString('da-DK') + suffix);
        }

        formatValuesInput[handle].val(Math.trunc(values[handle]));
      });
    }
  });
});

$(document).ready(function() {
  $('.checkbox-btn').click(function() {
    var isChecked = $(this).parent().siblings($('.checkbox')).is(':checked')

    if (isChecked) {
      $(this).removeClass('checked')
    } else {
      $(this).addClass('checked')
    }
  });
});

$(document).ready(function() {
  $('.sqm-property').each(function(){
    var td = $(this)
    var address = td.siblings('.property-address').text().trim()
    var url = "https://api.dataforsyningen.dk/adresser?q=" + address
    
    $.ajax({
      url: url,
      success:
        function(data) {
          var addressId = data[0].id
          var enhedUrl = "https://api.dataforsyningen.dk/bbrlight/enheder?adresseid=" + addressId;
          $.ajax({
            url: enhedUrl,
            success: function(data){
              if (data.length !== 0) {
                td.text(data[0]['ENH_ARL_SAML'])
                var final_price = td.siblings('.final-price-property').text().replace(/\D/g,'');
                var sqmPrice = final_price / data[0]['ENH_ARL_SAML']
                td.siblings('.sqm-price-property').text(Math.trunc(sqmPrice).toLocaleString('da-DK') + ' kr.');
              }
            }
          })
        }
    })
  });
});

$(document).ready(function() {
  $('#postal-code-input').on('input', function() {
    var inputVal = $(this).val();

    if (inputVal.length >= 2) {
      var firstLetter = inputVal.slice(0, 1).toUpperCase();
      var remainingLetters = inputVal.slice(1);
      var formattedInputValue = firstLetter + remainingLetters;
      var apiUrl = 'https://api.dataforsyningen.dk/postnumre?q=' + formattedInputValue + '*';
      
      $.ajax({
        url: apiUrl,
        success: function(data) {
          var suggestionsList = $('#suggestions-list');
          suggestionsList.empty();

          if (data.length > 0) {
            data.forEach(function(item) {
              if (!isItemInList(item.navn, 'suggestions-list')) {
                var listItem = $('<li>', {
                  text: item.navn + ' (' + item.nr + ')',
                  class: 'postal-code-suggestion'
                });
                listItem.on('click', function() {
                  var postalCode = item.nr;
                  if (!isItemInList(postalCode, 'postal-code-list')) {
                    addPostalCodeItem(postalCode, item.navn);
                  }
                });

                suggestionsList.append(listItem);
              }
            });
          } else {
            var listItem = $('<li>').text('No suggestions found');
            suggestionsList.append(listItem);
          }
        }});
    } else {
      var suggestionsList = $('#suggestions-list');
      suggestionsList.empty();
    }
  });

  function isItemInList(item, listId) {
    var listItems = $('#' + listId + ' li');
    return listItems.filter(function() {
      return $(this).text().includes(item);
    }).length > 0;
  }

  function addPostalCodeItem(postalCode, name) {
    var postalCodeList = $('#postal-code-list');
    var listItem = $('<li/>', {
      text: name + ' (' + postalCode + ')',
      class: 'postal-code-item'
    });
    postalCodeList.append(listItem);
    
    var hiddenFieldsContainer = $('#postal-code-hidden-fields');
    // Create a hidden field with the postal code value
    var hiddenField = $('<input/>', {
      type: 'hidden',
      name: 'postal_codes[]',
      value: postalCode,
      id: 'postal-code-input-' + postalCode
    });

    // Append the hidden field to the hidden fields container
    hiddenFieldsContainer.append(hiddenField);
  }

  var postalCodeList = $('#postal-code-list');
  // Delete postal code on click
  postalCodeList.on('click', '.postal-code-item', function() {
    var postalCode = $(this).text().replace(/\D/g,'');
    var hiddenFieldId = 'postal-code-input-' + postalCode;
    $(this).remove();
    $('#' + hiddenFieldId).remove();
  });

  $('#postal-code-input').on('keydown', function(event) {
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent form submission
    }
  });
});

$(document).ready(function() {
  var selectedRecommendations = [];

  $('.recommendation-checkbox').on('change', function() {
    // Clear the selectedRecommendations array
    selectedRecommendations.length = 0;

    // Find all selected checkboxes and get their values (recommendation IDs)
    $('.recommendation-checkbox:checked').each(function() {
      selectedRecommendations.push($(this).val());
    });

    if (selectedRecommendations.length == 0) {
      $('#send-recommendations-btn').addClass('disabled')
      $('#send-recommendations-tooltip').show()
    } else {
      $('#send-recommendations-btn').removeClass('disabled')
      $('#send-recommendations-tooltip').hide()
    }

    // Update the hidden input field with the selected recommendation IDs as an array
    $('#selected-recommendations').val(JSON.stringify(selectedRecommendations));
  });
})

$(document).ready(function() {
  // Define a debounced function that handles the AJAX request
  var debounceAjax = _.debounce(function(form) {
    $.ajax({
      url: form.attr('action'),
      type: form.attr('method'),
      data: form.serialize(),
      success: function(response) {
        // Handle the server's response here, if needed.
      }
    });
  }, 1000); // Adjust the debounce delay as needed (in milliseconds)

  $('.property-description').on('input', function() {
    var form = $(this).closest('form');
    debounceAjax(form); // Trigger the debounced function
  });
});

$(document).ready(function () {
  // Function to toggle visibility based on conditions
  function toggleVisibility(element, shouldShow) {
    if (shouldShow) {
      element.removeClass('d-none');
    } else {
      element.addClass('d-none');
      element.val('');
    }
  }

  // Deal source change event
  $('#deal_source').change(function () {
    const isPartner = $(this).val() === 'partner';
    const isRepeatCustomer = $(this).val() === 'repeat_customer';
    toggleVisibility($('#deal_source_partner, #deal_source_partner_label_id'), isPartner);
    toggleVisibility($('#deal_source_subtype, #deal_source_subtype_label_id'), isRepeatCustomer);
  });

  // Deal state change event
  $('#deal_state').change(function () {
    const isClosed = $(this).val() === 'closed';
    toggleVisibility($('#deal_closed_reason, #deal_closed_reason_label_id'), isClosed);
    toggleVisibility($('#deal_closed_reason_description, #deal_closed_reason_description_label_id'), false);
  });

  // Closed reason change event
  $('#deal_closed_reason').change(function () {
    const value = $(this).val();
    const isOther = ['other', 'case_other'].includes(value);
    toggleVisibility($('#deal_closed_reason_description, #deal_closed_reason_description_label_id'), isOther);
  });

  // KYC Events toggle

  $('#kyc_funds_from_cash').change(function () {
    const value = $(this).val();
    const isOther = ['true'].includes(value);
    toggleVisibility($('#kyc_funds_from_cash_amount, #kyc_funds_from_cash_amount_label_id'), isOther);
  });

  $('#kyc_funds_from_cash').change(function () {
    const value = $(this).val();
    const isOther = ['true'].includes(value);
    toggleVisibility($('#kyc_funds_from_cash_origin, #kyc_funds_from_cash_origin_label_id'), isOther);
  });

  $('#kyc_purpose').change(function () {
    const value = $(this).val();
    const isOther = ['other'].includes(value);
    toggleVisibility($('#kyc_purpose_description, #kyc_purpose_description_label_id'), isOther);
  });

  $('#kyc_funds_from_others').change(function () {
    const value = $(this).val();
    const isOther = ['true'].includes(value);
    toggleVisibility($('#kyc-relations-list'), isOther);
  });

  $('#secondary_citizenship_checkbox').on('click', function () {
    var secondaryCitizenShipElm = $('#kyc_secondary_citizenship');

    if ($(this).prop('checked')) {
      toggleVisibility(secondaryCitizenShipElm, true);
      secondaryCitizenShipElm.prop('disabled', false);
    } else {
      toggleVisibility(secondaryCitizenShipElm, false);
      secondaryCitizenShipElm.prop('disabled', 'disabled');
    }
    
  });
});

$(document).ready(function() {
  var branchElm = $('#meeting_branch_id');

  branchElm.hide();

  $('#meeting_web_meeting').on('click', function() {
    if ($(this).prop('checked')) {
      branchElm.hide();
      branchElm.prop('disabled', 'disabled');
    } else {
      branchElm.show();
      branchElm.prop('disabled', false);
    }
  });
});

$(document).ready(function(){
  var collapseAll = $('#collapse-all')

  collapseAll.on('click', function(){
    var cards = $('.card-options-collapse').closest('.card');
    var collapsedCards = cards.filter('.card-collapsed')

    if (collapsedCards.length != cards.length) {
      cards.addClass('card-collapsed');
      collapseAll.addClass('fe-chevron-down').removeClass('fe-chevron-up')
    } else {
      $('.card-options-collapse').trigger('click');
      collapseAll.toggleClass('fe-chevron-up fe-chevron-down');
    }
  });
});

$(document).ready(function() {
  
  // Listen for changes in funds_from_others
  $('#kyc_funds_from_others').change(function() {
    toggleKycFields();
  });

  function toggleKycFields() {
    var fundsFromOthers = $('#kyc_funds_from_others').val();
    const isTrue = ['true'].includes(fundsFromOthers);
    $('#kyc-relations-list input, #kyc-relations-list select').prop('disabled', !isTrue);
  }
});

$(document).ready(function(){
  // Remove fields dynamically
  $('form').on('click', '.remove-fields', function (e) {
    e.preventDefault();

    $(this).closest('.nested-fields').remove();
  });
});

$(document).ready(function() {
  $('#copyButton').click(function() {
    document.getElementById('copyButton').classList.add('clicked');
    var copyText = $('#url-input')[0]; // Replace 'link' with the actual ID of your text_field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(copyText.value);
    setTimeout(function() {
      document.getElementById('copyButton').classList.remove('clicked');
    }, 50); // Remove the effect after 1 second (1000 milliseconds)
  });
});

$(document).ready(function() {
  $('#modal-new_customer_added').modal('show');
});

$(document).ready(function() {
  const productRadios = document.querySelectorAll('input[name="order[product_id]"]');
  const specificPropertyFields = document.querySelector('.specific-property-fields');
  const specificPropertyToggle = document.getElementById('specific_property_toggle');
  const specificPropertyAddress = document.querySelector('.specific-property-address');

  productRadios.forEach(radio => {
    radio.addEventListener('change', () => {
      if (radio.id === 'order_product_id_1' && radio.checked) {
        specificPropertyFields.classList.remove('d-none');
      } else {
        specificPropertyFields.classList.add('d-none');
        specificPropertyToggle.checked = false; // Reset toggle
        specificPropertyAddress.classList.add('d-none'); // Hide address field
      }
    });
  });

  if (specificPropertyToggle) {
    specificPropertyToggle.addEventListener('change', () => {
      if (specificPropertyToggle.checked) {
        specificPropertyAddress.classList.remove('d-none');
      } else {
        specificPropertyAddress.classList.add('d-none');
      }
    });
  }
})
